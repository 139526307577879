<template>
  <div class="screen" v-bind:class="{'full-screen':fullScreen}" v-bind:style="colorStyle">

    <!--            <img class="bg" src="@/assets/img/bg_hillbo.jpg"/>-->
    <img class="bg" :src="background"/>

    <!--顶部标题-->
    <div class="title">
      <div class="item-bg">{{ screen.title || "[大屏标题]" }}</div>
    </div>

    <!--右上角时间-->
    <CurrentTime class="time item-bg" :langRes="langRes"></CurrentTime>

    <div class="content-row">

      <!--参数区-->
      <div class="paras">
        <ScreenParaView class="item-bg" v-bind:class="paraClass" v-for="item in paraArray"
                        :langRes="langRes"
                        :model="item" :valueMap="valueMap"></ScreenParaView>
      </div>

      <!--公告栏-->
      <div class="notice item-bg" v-if="screen.notice">
        <div class="notice-title">{{ langRes.noticeTitle }}</div>
        <div class="notice-content" v-html="screen.notice"></div>
      </div>
    </div>

  </div>
</template>

<script>
import Lang from "./Lang"
import CurrentTime from "./_components/CurrentTime";
import ScreenParaView from "./_components/ScreenParaView";

export default {
  components: {CurrentTime, ScreenParaView},
  props: ["screen", "valueMap", "langZh", "deviceId"],
  data() {
    return {
      paraArray: []
    }
  },
  computed: {
    langRes() {
      return this.langZh ? Lang.zh : Lang.en
    },
    background() {
      return this.screen.background || require('@/assets/img/bg_hillbo.jpg')
    },
    colorStyle() {
      return {
        fontSize: this.screen.fontSize + "px",
        color: this.screen.textColor || "#0084FF"
      }
    },
    paraClass() {
      //6个参数内，每行2个;多于6个参数时，每行3个
      return this.paraArray.length > 6 ? "data-item-3" : "data-item-2"
    }
  },
  created() {
    this.$get(`web/iot/water/screen/paras/${this.deviceId}`).then(suc => {
      this.paraArray = suc.data
    })
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.screen {
  position: relative;
  background: #000;
  font-size: 100px;
  display: flex;
  flex-direction: column;
  font-family: SimHei, STHeiti;
  height: 100%;

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    object-fit: cover;
    opacity: 1;
  }


  .title {
    text-align: center;
    font-size: 50%;
    padding: 10px;
    z-index: 1;
    display: flex;
    justify-content: center;

    div {
      padding: 5px 20px;
    }
  }

  .time {
    position: absolute;
    bottom: 10px;
    right: 10px;
    text-align: right;
    font-size: 20%;
    padding: 10px 20px;
  }

  .item-bg {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
  }

  .content-row {
    display: flex;
    align-items: center;
    flex: 1;
    z-index: 1;

    .paras {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 3%;


      .data-item-2 {
        width: 48%;
        margin: 1%;
        font-size: 120%;
      }

      .data-item-3 {
        width: 31.3%;
        margin: 1%;
        font-size: 90%;
      }
    }

    .notice {
      width: 28%;
      align-self: start;
      margin-right: 10px;
      margin-top: 60px;

      .notice-title {
        text-align: center;
        padding: 15px 20px;
        border-bottom: 2px dashed rgba(0, 0, 0, 0.1);
        font-size: 22%;
      }

      .notice-content {
        text-align: left;
        padding: 15px 20px;
        font-size: 18%;
        min-height: 300px;
      }
    }


  }
}

.full-screen {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  position: fixed;
  font-size: 120px;
}

.screen-actions {
  position: absolute;
  display: flex;
  left: 10px;
  top: 10px;
  z-index: 1;
  opacity: 0.6;

  * {
    margin-right: 10px;
  }

  .btn-full-screen {
    border: 1px solid #777;
  }

  .font-size-slider {
    margin: 10px auto;
  }
}


</style>
